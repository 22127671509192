export default {
    //token
    token:state => state.token,
    //操作参数
    operateParams:state => state.operateParams,
    //流程数据
    processData:state => state.processData,
    //taskid
    taskId:state => state.taskId,
    disabled:state => state.disabled,
    userInfo:state => state.userInfo
}