<template>
  <div :id="containerId" style="position: relative">
      <!--  ---------------------------- begin 图片左右换位置 ------------------------------------- -->
    <div class="movety-container" :style="{top:top+'px',left:left+'px',display:moveDisplay}" style="padding:0 8px;position: absolute;z-index: 91;height: 32px;width: 104px;text-align: center;">
      <div :id="containerId+'-mover'" :class="showMoverTask?'uploadty-mover-mask':'movety-opt'" style="margin-top: 12px">
        <a @click="moveLast" style="margin: 0 5px;"><a-icon type="arrow-left" style="color: #fff;font-size: 16px"/></a>
        <a @click="moveNext" style="margin: 0 5px;"><a-icon type="arrow-right" style="color: #fff;font-size: 16px"/></a>
      </div>
    </div>
    <!--  ---------------------------- end 图片左右换位置 ------------------------------------- -->
    <a-upload
      name="file"
      :multiple="multiple"
      :action="uploadAction"
      :headers="headers"
      :data="{biz:bizPath}"
      :fileList="fileList"
      :beforeUpload="beforeUpload"
      :disabled="disabled"
      @change="handleChange"
      @preview="handlePreview"
      :returnUrl="returnUrl"
      :listType="complistType"
      :class="{'uploadty-disabled':disabled}"
      >
      <template v-if="fileList.length < number">
        <div v-if="isImageComp">
          <a-icon type="plus" />
          <div class="ant-upload-text">{{ text }}</div>
        </div>
        <a-button v-else-if="buttonVisible">
          <a-icon type="upload" />{{ text }}
        </a-button>
      </template>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
  import { getFileAccessHttpUrl } from '@/utils/util'
  import {domianURL,getFile} from '@/api/api'
  import store from "@/store/index";

  const FILE_TYPE_ALL = "all"
  const FILE_TYPE_IMG = "image"
  const FILE_TYPE_TXT = "file"
  const uidGenerator=()=>{
    return '-'+parseInt(Math.random()*10000+1,10);
  }
  const getFileName=(path)=>{

    if(path.lastIndexOf("\\")>=0){
      let reg=new RegExp("\\\\","g");
      path = path.replace(reg,"/");
    }
   
    if (path.lastIndexOf("/")>=0) {
        return path.substring(path.lastIndexOf("/")+1);
    } else {
        return path;
    }
  }
  export default {
    name: 'JImageUpload',
    data(){
      return {
        uploadAction:domianURL+"/sys/oss/api/uploadRequest",
        headers:{},
        fileList: [],
        newFileList: [],
        uploadGoOn:true,
        previewVisible: false,
        //---------------------------- begin 图片左右换位置 -------------------------------------
        previewImage: '',
        containerId:'',
        top:'',
        left:'',
        moveDisplay:'none',
        showMoverTask:false,
        moverHold:false,
        currentImg:''
      }
    },
    props:{
      text:{
        type:String,
        required:false,
        default:"点击上传"
      },
      fileType:{
        type:String,
        required:false,
        default:FILE_TYPE_ALL
      },
      upFileType:{
        type:String,
        required:false,
        default:''
      },
      /*这个属性用于控制文件上传的业务路径*/
      bizPath:{
        type:String,
        required:false,
        default:"temp"
      },
      value:{
        type:[String,Array],
        required:false
      },
    //   附件类型
      attType:{
        type:String,
        required:false,
        default:""
      },
      // update-begin- --- author:wangshuai ------ date:20190929 ---- for:Jupload组件增加是否能够点击
      disabled:{
        type:Boolean,
        required:false,
        default: false
      },
      // update-end- --- author:wangshuai ------ date:20190929 ---- for:Jupload组件增加是否能够点击
      //此属性被废弃了
      triggerChange:{
        type: Boolean,
        required: false,
        default: false
      },
      /**
       * update -- author:lvdandan -- date:20190219 -- for:Jupload组件增加是否返回url，
       * true：仅返回url
       * false：返回fileName filePath fileSize
       */
      multiple:{
        type:Boolean,
        required:false,
        default: true
      },
      returnUrl:{
        type:Boolean,
        required:false,
        default: true
      },
      number:{
        type:Number,
        required:false,
        default: 1
      },
      buttonVisible:{
        type:Boolean,
        required:false,
        default: true
      },
      //update-end-author:wangshuai date:20201021 for:LOWCOD-969 新增number属性，用于判断上传数量
    },
    watch:{
      value: {
        handler(val,oldValue) {
          if (val instanceof Array) {
            this.initFileList(val)
          } else {
              if (val) {
                  this.initFileList(val.split(','))
              } else {
                  this.initFileList(val)
              }
            
          }
          if(!val || val.length==0){
            this.picUrl = false;
          }
        },
        //立刻执行handler
        immediate: true
      }
    },
    created(){
      
      // const token = Vue.ls.get(ACCESS_TOKEN);
      const token = store.state.token
      //---------------------------- begin 图片左右换位置 -------------------------------------
      this.headers = {"X-Access-Token":token};
      this.containerId = 'container-ty-'+new Date().getTime();
      //---------------------------- end 图片左右换位置 -------------------------------------
    },
    computed:{
      isImageComp(){
        return this.fileType === FILE_TYPE_IMG
      },
      complistType(){
        return this.fileType === FILE_TYPE_IMG?'picture-card':'text'
      }
    },
    methods:{
      getFileList(ids) {
        return getFile({ids}).then(res => {
          if (res.success) {
            let newArr = res.result.map(el=>{
              return {
                ...el,
                attId:el.id
              }
            });
            return newArr
          }
        })
      },
      initFileList(paths){
        // console.log('=======paths',paths)
        if(!paths || paths.length==0){
          this.fileList = [];
          return;
        }
        let arr = paths
        let isUrl = paths.map(el =>!!el.url)
        // console.log('======isUrl',isUrl)
        if(isUrl.indexOf(false)>-1) {
          let ids = paths.map(el =>el.attId).join(',')
          this.getFileList(ids).then(res => {
            this.hanlerData(res)
          })
        }else {
          this.hanlerData(arr)
        }
        
      },
      hanlerData(arr) {
        // console.log('=======arr',arr)
        let fileList = [];
        for(var a=0;a<arr.length;a++){
          let url = getFileAccessHttpUrl(arr[a].url);
          let attId = arr[a].attId;
          let fileName = arr[a].fileName;
          fileList.push({
            uid: uidGenerator(),
            name: fileName,
            status: 'done',
            url: url,
            attId: attId,
            response:{
              status:"history",
              message:arr[a].url,
              result: {
                url,
                id: attId,
                fileName
              },
            }
          })
        }
        console.log('====================================');
        console.log('initFileList22222222222222:',fileList);
        console.log('====================================');
        this.fileList = fileList
      },
      beforeUpload: function(file){
        console.log('=========上传前file',file);
        this.uploadGoOn=true
        var fileType = file.type;
        if(this.fileType===FILE_TYPE_IMG){
          if(fileType.indexOf('image')<0){
            this.$message.warning('请上传图片');
            this.uploadGoOn=false
            return false;
          }
        }else {//all||file
        console.log('============this.file',this.upFileType)
          if(this.upFileType && fileType.indexOf(this.upFileType)==-1) {
            let format = this.upFileType == 'pdf' ?'PDF文档'
                      : this.upFileType == 'word' ?'Word文档'
                      : this.upFileType == 'excel'?'Excel文档':''
            this.$message.warning('请上传'+format);
            this.handlePathChange()
            return false
          }
          //TODO 扩展功能验证文件大小
          return true
        }
      },
      handleChange(info) {
        this.picUrl = false;
        let fileList = info.fileList

        //update-end-author:wangshuai date:20201022 for:LOWCOD-969 判断number是否大于0和是否多选，返回选定的元素。
        if(info.file.status==='done'){
          if (this.number > 0) {
            fileList = fileList.slice(-this.number)
          } else {
            fileList = fileList
          }
          if(info.file.response.success){
            this.picUrl = true;
            fileList = fileList.map((file) => {
              if (file.response) {
                file.url = file.response.result.url;
                file.attId = file.response.result.id;
                file.fileName = file.name;
              }
              return file;
            });
          }
          //this.$message.success(`${info.file.name} 上传成功!`);
        }else if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 上传失败.`);
        }else if(info.file.status === 'removed'){
          this.handleDelete(info.file)
        }
        // if (this.number > 0) {
        //   this.fileList = fileList.slice(-this.number)
        // } else {
        //   this.fileList = fileList
        // }
        this.fileList = fileList
        if(info.file.status==='done' || info.file.status === 'removed'){
          this.handlePathChange()
        }
      },
      // 预览
      handlePreview (file) {
        if(this.fileType === FILE_TYPE_IMG){
          this.previewImage = file.url || file.thumbUrl;
          this.previewVisible = true;
        }else{
          location.href=file.url
        }
      },
      getAvatarView(){
        if(this.fileList.length>0){
          let url = this.fileList[0].url
          return getFileAccessHttpUrl(url)
        }
      },
      handlePathChange(){
        let uploadFiles = this.fileList
        console.log('====================================');
        console.log('handlePathChange444444444444444', uploadFiles);
        console.log('====================================');
        let path = []
        if(!uploadFiles || uploadFiles.length==0){
          path = []
        }
        let arr = [];
        for(let a=0;a<uploadFiles.length;a++){
            // update-begin-author:taoyan date:20200819 for:【开源问题z】上传图片组件 LOWCOD-783
            if(uploadFiles[a].status === 'done' ) {
              arr.push({
                  attId: uploadFiles[a].response.result.id,
                  url: uploadFiles[a].response.result.url,
                  fileName: uploadFiles[a].name,
              })
            }else{
              return;
            }
            // update-end-author:taoyan date:20200819 for:【开源问题z】上传图片组件 LOWCOD-783
        }
        if(arr.length>0){
          path = arr
        }
        this.$emit('change', path);
      },
      handleDelete(file){
        //如有需要新增 删除逻辑
        console.log(file)
      },
      handleCancel() {
        this.previewVisible = false;
      },
      //---------------------------- begin 图片左右换位置 -------------------------------------
      moveLast(){
        //console.log(ev)
        //console.log(this.fileList)
        //console.log(this.currentImg)
        let index = this.getIndexByUrl();
        if(index==0){
          this.$message.warn('未知的操作')
        }else{
          let curr = this.fileList[index].url;
          let last = this.fileList[index-1].url;
          let arr =[]
          for(let i=0;i<this.fileList.length;i++){
            if(i==index-1){
              arr.push(curr)
            }else if(i==index){
              arr.push(last)
            }else{
              arr.push(this.fileList[i].url)
            }
          }
          this.currentImg = last
          this.$emit('change',arr.join(','))
        }
      },
      moveNext(){
        let index = this.getIndexByUrl();
        if(index==this.fileList.length-1){
          this.$message.warn('已到最后~')
        }else{
          let curr = this.fileList[index].url;
          let next = this.fileList[index+1].url;
          let arr =[]
          for(let i=0;i<this.fileList.length;i++){
            if(i==index+1){
              arr.push(curr)
            }else if(i==index){
              arr.push(next)
            }else{
              arr.push(this.fileList[i].url)
            }
          }
          this.currentImg = next
          this.$emit('change',arr.join(','))
        }
      },
      getIndexByUrl(){
        for(let i=0;i<this.fileList.length;i++){
          if(this.fileList[i].url === this.currentImg || encodeURI(this.fileList[i].url) === this.currentImg){
            return i;
          }
        }
        return -1;
      }
    },
    mounted(){
      const moverObj = document.getElementById(this.containerId+'-mover');
      if(moverObj){
        moverObj.addEventListener('mouseover',()=>{
          this.moverHold = true
          this.moveDisplay = 'block';
        });
        moverObj.addEventListener('mouseout',()=>{
          this.moverHold = false
          this.moveDisplay = 'none';
        });
      }
    
      let picList = document.getElementById(this.containerId)?document.getElementById(this.containerId).getElementsByClassName('ant-upload-list-picture-card'):[];
      if(picList && picList.length>0){
        picList[0].addEventListener('mouseover',(ev)=>{
          ev = ev || window.event;
          let target = ev.target || ev.srcElement;
          if('ant-upload-list-item-info' == target.className){
            this.showMoverTask=false
            let item = target.parentElement
            this.left = item.offsetLeft
            this.top=item.offsetTop+item.offsetHeight-50;
            this.moveDisplay = 'block';
            this.currentImg = target.getElementsByTagName('img')[0].src
          }

        });

        picList[0].addEventListener('mouseout',(ev)=>{
          ev = ev || window.event;
          let target = ev.target || ev.srcElement;
          //console.log('移除',target)
          if('ant-upload-list-item-info' == target.className){
            this.showMoverTask=true
            setTimeout(()=>{
              if(this.moverHold === false)
                this.moveDisplay = 'none';
            },100)
          }
          if('ant-upload-list-item ant-upload-list-item-done' == target.className || 'ant-upload-list ant-upload-list-picture-card'== target.className){
            this.moveDisplay = 'none';
          }
        })
        //---------------------------- end 图片左右换位置 -------------------------------------
      }
    },
    model: {
      prop: 'value',
      event: 'change'
    }
  }
</script>

<style scoped lang="less">
  /* update--begin--autor:lvdandan-----date:20201016------for：j-image-upload图片组件单张图片详情回显空白
  * https://github.com/zhangdaiscott/jeecg-boot/issues/1810
  * https://github.com/zhangdaiscott/jeecg-boot/issues/1779
  */

  /deep/ .imgupload .iconp{padding:20px;}
  /* update--end--autor:lvdandan-----date:20201016------for：j-image-upload图片组件单张图片详情回显空白*/

  /deep/ .image-upload-single-over .ant-upload-select{display: none}
</style>
