<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {
  getQueryString
} from '@/utils/util'
export default {
    mounted() {
        window.addEventListener('unload', this.saveState());
        // console.log('==========unload')
    },
    provide(){
      return{
        closeCurrent:this.closeCurrent
      }
    },
    methods: {
        closeCurrent() {
          console.log('关闭页面-----------');
        },
        saveState() {
          let tokenCode = getQueryString('token');
          console.log('========tokenCode',tokenCode);
          if(tokenCode) {
            let token = this.$Base64.decode(tokenCode);
            // let token = tokenCode;
            this.$store.state.token = token;
          }
          // console.log('this.$store.state----------', this.$store.state);
          sessionStorage.setItem('state', JSON.stringify(this.$store.state));
        }
    }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}
</style>
