import * as types from './mutation-types'
export default {
    [types.Set_token](state, data) {
        state.token = data;
    },
    [types.Set_operateParams](state, data) {
        state.operateParams = data;
    },
    [types.Set_processData](state, data) {
        state.processData = data;
    },
    [types.SET_taskId](state, data) {
        state.taskId = data;
    },
    [types.Set_disabled](state, data) {
        state.disabled = data;
    },
    [types.Set_userInfo](state, data) {
        state.userInfo = data;
    },
}