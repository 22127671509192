import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from "@/store/index";
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
//入口
const Home = () => import(/* webpackChunkName: "home" */ "../views/Home.vue");
//借贷--审核
const Examine = () => import(/* webpackChunkName: "examine" */ "../views/loan/Examine.vue");

//合同详情
const RelationContent = () => import(/* webpackChunkName: "businessPlatform" */ "../views/business-platform/seal/contact/relationContent");
//合同盖章
const ContactTemplate = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/contact/textTemplate");
const Seal = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/contact/seal.vue");
//合同补充协议盖章
const ContactSupplement = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/contact/supplement/supplementPreview");
const ContactSupplementSeal = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/contact/supplement/supplementSeal");

//招标信息
const BiddingInfo = () => import(/* webpackChunkName: "businessPlatform" */ "../views/business-platform/biddingInfo");
//Onlyoffice 暂时没用
const Onlyoffice = () => import(/* webpackChunkName: "businessPlatform" */ "../views/business-platform/selectTemplate");

//pdf盖章
const PdfSeal = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/pdf/pdfSealModel");

const PromoteChart = () => import(/* webpackChunkName: "HR-platform" */ "../views/HR-platform/promoteChart");
const DetailedList = ()=> import(/* webpackChunkName: "HR-platform" */ "../views/HR-platform/detailedList");
const HrUserRoleList = ()=> import(/* webpackChunkName: "HR-platform" */ "../views/HR-platform/HrUserRoleList");
const HrProject = ()=> import(/* webpackChunkName: "hrTask" */ "../views/hrProjectManage/hrProject/HrProject");
const HrTask = ()=> import(/* webpackChunkName: "hrTask" */ "../views/hrProjectManage/hrTask/HrTask");
const historyInfo = ()=> import(/* webpackChunkName: "hrTask" */ "../views/activiti/historyInfo");
const GanttModal = ()=> import(/* webpackChunkName: "hrTask" */ "../views/hrProjectManage/hrProject/modules/GanttModal");
const RelationCharts = ()=> import(/* webpackChunkName: "hrTask" */ "../views/RelationCharts/RelationCharts");
const hrTaskActiviti = ()=> import(/* webpackChunkName: "hrTask" */ "../views/hrProjectManage/hrTaskActiviti/hrTaskActiviti");
const loginActiviti = ()=> import(/* webpackChunkName: "hrTask" */ "../views/hrProjectManage/hrTaskActiviti/loginActiviti");
const chinaEacharts = ()=> import(/* webpackChunkName: "hrTask" */ "../views/chinaEacharts");

//公文管理(红头文件)
const officialdocument = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/officialDoc/officedocument.vue");
const sealfile = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/officialDoc/sealfile.vue");
//二维码详情
const qrcodeurl = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/officialDoc/qrcodedetails.vue");
const JYqrcodeurl = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/officialDoc/JYqrcodedetails.vue");
const SignedApplicationUrl = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/pdf/SignedApplication.vue");
//中间跳转页面
const TurningThePage = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/pdf/TurningThePage.vue");

//多附件盖章-用印
const moreaccessories = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/signet/moreaccessories.vue");
const multiattachmentseal = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/signet/multiattachmentseal.vue");

//用印申请详情页
const usingtheapplication = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/signet/usingtheapplication.vue");
//用印申请详情页智勋
const NewUsingtheapplication = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/signet/Newusingtheapplication.vue");

//检测中心--合同盖章
const ContactPreview = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/previewModel.vue")
const ContactSeal = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/sealModel.vue")
const TestContactInfo = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/contractInfo.vue")
const newTestContactInfo = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/newcontractInfo.vue")
const PersonCertificateInfo = ()=> import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/PersonCertificateInfo.vue")
const tableindex = ()=> import(/* webpackChunkName: "seal" */ "../views/table/index.vue")
const Pcdetails = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/seal/testCenter/contact/pcdetails.vue")

const PcReport = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/pc/MYFJ.vue")
const PcReportInfo = () => import(/* webpackChunkName: "seal" */ "../views/business-platform/pc/reportInfo.vue")

//合同标准模板
const StandardContractTemplate = () => import(/* webpackChunkName: "PC" */ "../views/business-platform/pc/contactTemplate")

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},{
		path: '/loan/examine',
		name: 'Examine',
		component: Examine
	},{
		path: '/businessPlatform/biddingInfo',
		name: 'BiddingInfo',
		component: BiddingInfo
	},{
		path: '/businessPlatform/contactTemplate',
		name: 'ContactTemplate',
		component: ContactTemplate
	},{
		path: '/businessPlatform/seal',
		name: 'Seal',
		component: Seal
	},{
		path: '/businessPlatform/pdfSeal',
		name: 'PdfSeal',
		component: PdfSeal
	},{
		path: '/businessPlatform/relationContent',
		name: 'RelationContent',
		component: RelationContent,
	},{
		path: '/HR-platform/promoteChart',
		name: 'PromoteChart',
		component: PromoteChart
	},{
		path: '/HR-platform/detailedList',
		name: 'DetailedList',
		component: DetailedList
	},{
		path: '/HR-platform/users',
		name: 'HrUserRoleList',
		component: HrUserRoleList
	},{
		path: '/onlyoffice',
		name: 'Onlyoffice',
		component: Onlyoffice
	},{
		path: '/hrProjectManage/hrProject',
		name: 'HrProject',
		component: HrProject
	},{
		path: '/hrProjectManage/hrTask',
		name: 'HrTask',
		component: HrTask
	},{
		path: '/hrProjectManage/hrTaskActiviti',
		name: 'hrTaskActiviti',
		component: hrTaskActiviti
	},{
		path: '/hrProjectManage/loginActiviti',
		name: 'loginActiviti',
		component: loginActiviti
	},{
		path: '/activitiPage',
		name: 'ActivitiPage',
		component: historyInfo
	},{
		path: '/gantt/hrProjectManage',
		name: 'Gantt',
		component: GanttModal
	},{
		path: '/eCharts/relationCharts',
		name: 'RelationCharts',
		component: RelationCharts
	},
  {
		path: '/eCharts/chinaEacharts',
		name: 'ChinaEacharts',
		component: chinaEacharts
	},
  {
		path: '/businessPlatform/officedocument', //公文管理(红头文件)
		name: 'OfficialDocument',
		component: officialdocument
	},{
		path: '/businessPlatform/sealfile', //公文管理(红头文件)盖章
		name: 'sealfile',
		component: sealfile
	},{
		path: '/businessPlatform/qrcodedetails', //公文管理(红头文件)二维码详情
		name: 'qrcodeurl',
		component: qrcodeurl
	},
  {
		path: '/businessPlatform/JYqrcodedetails', //经营项目管理二维码详情
		name: 'JYqrcodeurl',
		component: JYqrcodeurl
	},
  {
		path: '/businessPlatform/SignedApplication', //经营项目管理二维码详情
		name: 'SignedApplication',
		component: SignedApplicationUrl
	},
  {
		path: '/businessPlatform/TurningThePage', //中间跳转页面
		name: 'TurningThePage',
		component: TurningThePage
	},
  {
		path: '/businessPlatform/moreaccessories', //多附件列表预览页
		name: 'moreaccessories',
		component: moreaccessories
	},{
			path: '/businessPlatform/multiattachmentseal', //多附件盖章页
			name: 'multiattachmentseal',
			component: multiattachmentseal
	},{
			path: '/businessPlatform/usingtheapplication', //用印申请详情页
			name: 'usingtheapplication',
			component: usingtheapplication
	},{
		path: '/businessPlatform/NewUsingtheapplication', //用印申请详情页
		name: 'usingtheapplication',
		component: NewUsingtheapplication
},{
			path: '/businessPlatform/multiattachmentseal', //多附件盖章页
			name: 'multiattachmentseal',
			component: multiattachmentseal
	},{
		path: '/businessPlatform/contactSupplement', //合同附件预览
		name: 'contactSupplement',
		component: ContactSupplement
	},{
		path: '/businessPlatform/contactSupplementSeal', //合同附件盖章
		name: 'contactSupplementSeal',
		component: ContactSupplementSeal
	},{
		path:'/testCenter/contactPreview',
		name:'contactPreview',
		component:ContactPreview
	},{
		path:'/testCenter/contactSeal',
		name:'contactSeal',
		component:ContactSeal
	},
	{
		path:'/testCenter/contactInfo',
		name:'testContactInfo',
		component:TestContactInfo
	},
	{
		path:'/testCenter/newcontactInfo',
		name:'newTestContactInfo',
		component:newTestContactInfo
	},
	{
		path:'/businessPlatform/PersonCertificateInfo',
		name:'PersonCertificateInfo',
		component:PersonCertificateInfo
	},
	{
		path:'/table/index',
		name:'tableindex',
		component:tableindex
	},{
		path:'/businessPlatform/pcdetails',
		name:'pcdetails',
		component:Pcdetails
	},
	{
		path:'/businessPlatform/pcReport',
		name:'pcReport',
		component:PcReport
	},
	{
		path:'/businessPlatform/pcReportInfo',
		name:'pcReportInfo',
		component:PcReportInfo
	},
	{
		// 项目信息
		path:'/businessPlatform/projectInfo',
		name:'projectInfo',
		component: () => import('@/views/business-platform/project/info.vue')
	},
	{
		// 智勋项目信息
		path:'/businessPlatform/NewProjectInfo',
		name:'projectInfo',
		component: () => import('@/views/business-platform/project/NewInfo.vue')
	},
	{
		path:'/contact/standardTemplate',
		name:'standardContractTemplate',
		component:StandardContractTemplate
	}


]

const router = new VueRouter({
	mode: 'history',
	// base: '/loan/',
	routes
})


//路由守卫
// const token = store.state.token;
// router.beforeEach((to, from, next) => {
// 	if(!token && to.name != 'Home'){
// 		next('/');
// 	}else {
// 		next();
// 	}
// })

export default router
