import {staticDomainURL} from '@/api/api';
import ares from 'china-area-data'
import Area from './Area'
/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
    let event = document.createEvent('HTMLEvents')
    event.initEvent('resize', true, true)
    event.eventType = 'message'
    window.dispatchEvent(event)
}
/**
 * 如果值不存在就 push 进数组，反之不处理
 * @param array 要操作的数据
 * @param value 要添加的值
 * @param key 可空，如果比较的是对象，可能存在地址不一样但值实际上是一样的情况，可以传此字段判断对象中唯一的字段，例如 id。不传则直接比较实际值
 * @returns {boolean} 成功 push 返回 true，不处理返回 false
 */
 export function pushIfNotExist(array, value, key) {
    for (let item of array) {
      if (key && (item[key] === value[key])) {
        return false
      } else if (item === value) {
        return false
      }
    }
    array.push(value)
    return true
  }
  /**
 * 模糊查询要加*param*
 * 如 拜访页面搜索功能的 公司名称,拜访人
 */

export function preIcon(param) {
    return `*${param}*`
  } 
/**
 * 深度克隆对象、数组
 * @param obj 被克隆的对象
 * @return 克隆后的对象
 */
 export function cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj))
  }
/****获取参数*** */
export function getQueryString (name) {
    // console.log(name,window.location);
    // let search = window.location.search.substr(1) || window.location.hash.split("?")[1];
    // let reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
    // let r = search.match(reg);
    // if (r != null) return  unescape(r[2]); return null;
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"),
        r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]);
    return null;
}

/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar,subStr) {
    if(!subStr) subStr = 'http'
    try {
        if(avatar && avatar.startsWith(subStr)){
            return avatar;
        }else{
            if(avatar &&　avatar.length>0 && avatar.indexOf('[')==-1){
                return staticDomainURL + "/" + avatar;
            }
        }
    }catch(err){
        return;
    }
}
/*****
 * 对象去重
 * 
 *用法:delWeight(this.temp,'superviseTemplateId');
 */
export const delWeight = (temp,delID) => {
    let map = new Map();
    for (let item of temp) {
        // console.log('=========item[delID]',item[delID] != 'null');
        if (!map.has(item[delID])) {
            map.set(item[delID], item);
        }
    }
    // console.log('======去重',map)
    return [...map.values()];
}
/**
 * 过滤对象中为空的属性
 * @param obj
 * @returns {*}
 */
 export function filterObj(obj) {
    if (!(typeof obj == 'object')) {
      return;
    }
  
    for ( let key in obj) {
      if (obj.hasOwnProperty(key)
        && (obj[key] == null || obj[key] == undefined || obj[key] === '')) {
        delete obj[key];
      }
    }
    return obj;
  }
  
/****获取附件的类型**** */
export function ifFileType(text) {
  const regex = /(\.doc)$|(\.docx)$|(\.xls)$|(\.xlsx)$|(\.ppt)$|(\.pptx)$|(\.pdf)$/gm;
  let m;
  if ((m = regex.exec(text)) !== null) {
    console.log(`Found match, group : ${m[0]}`, m.index);
    return m[0]
  }
  return null
}

/*****打开新页面---解决浏览器拦截问题 */
export function openWin(url,id) {
  let a = document.createElement('a');  
  a.setAttribute('href', url);  
  a.setAttribute('target', '_blank');  
  a.setAttribute('id', id);  
  // 防止反复添加  
  if(!document.getElementById(id)) {                       
      document.body.appendChild(a);  
  }
  a.click();
  a.remove();
}

/****是不是数字类型* */
export function isNaNFlag(val) {
  return typeof parseInt(val) === 'number' && !isNaN(parseInt(val))
}

/******获取神市区 */
const areaData = new Area(ares);
export function getAres(value,type,child) {
  // console.log('======areaData',areaData)
  let code = areaData.getCode(value)
  let text = areaData.getTextStr(value)
  let textByPid = areaData.getCodeByPidAndText(value,child)
  // console.log('======code',code)
  // console.log('======text',text)
  switch (type) {
    case 'code':
      return code
    case 'text':
      return text
    default:
      return textByPid
  }
  // return type==='code'?code:text
}