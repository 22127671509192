<template>
    <a-select
        :mode="reMode"
        label-in-value
        v-model="selValue"
        :placeholder="placeholder"
        style="width: 100%"
        :not-found-content="fetching ? undefined : null"
        @search="fetchUser"
        @change="handleChange">
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="d in optinData" :key="d.value">
            {{ d.text }}
        </a-select-option>
    </a-select>
</template>

<script>
import {GetAtion} from "@/utils/http"
import {baseUrl} from '@/api/api'
export default {
    // name:'远程搜索并选择组件'
    props:{
        multi: {
            type:Boolean,
            default: true
        },
        url:{
            type:String,
            default:''
        },
        placeholder:{
            type:String,
            default:'请输入名称查询'
        },
        params:{
            type:Object,
            default:() =>{}
        },
        displayKey:{
            type:String,
            default:'name'
        },
        labelKeys:{
            type:Array,
            default:() =>[]
        }
    },
    data() {
        this.fetchUser = this.$Jlodash.debounce(this.fetchUser, 800);
        return {
            optinData: [],
            selValue: [],
            fetching: false,
            
        }
    },
    computed:{
        reMode() {
            return this.multi?'multiple':'default';
        },
        reUrl() {
            return baseUrl + this.url;
        }
    },
    methods:{
        fetchUser(value) {
            console.log('fetching user', value);
            this.optinData = [];
            this.fetching = true;
            this.params[this.displayKey] = value;
            GetAtion(this.reUrl,this.params)
            .then(res => {
                let resp = res.result.records || res.result;
                // console.log('搜索返回list',res);
                if(resp.length==0) {
                    this.$emit('selRows',[]);
                }
                let temp = {};
                this.labelKeys.forEach(val => {
                    temp[val] = resp.map(data => data[val]);
                })
                let optinData = []
                let keys = Object.keys(temp);
                temp[this.displayKey].forEach((item,index) => {
                    const elem = returnElem(index);
                    // console.log('==========elem',elem)
                    let obj = {
                        text:elem,
                        value:JSON.stringify(resp[index])
                    }
                    optinData.push(obj);
                })
                function returnElem(i) {
                    // console.log('========',keys.map(elem=> temp[elem][i]))
                    let str = keys.map(elem=> temp[elem][i]).join('_')
                    return  str
                }
                this.optinData = optinData;
            }).finally(() => {
                this.fetching = false;
            })
        },
        handleChange(value,option) {
            console.log('选择的结果',value,this.selValue);
            this.$emit('selRows',value);
        },
    }
}
</script>

<style scoped lang="less">

</style>