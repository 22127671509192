export default {
    //token
    token:'',
    //操作参数
    operateParams:{},
    //该用户的审核状态(0:未操作;1:操作过)
    processData:'',
    //taskid(审批要用)
    taskId:'',
    disabled:false,
    userInfo: {
        username: ''
    }
}