import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import {Tooltip} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JeecgComponents from '@/components/index';

Vue.config.productionTip = false

Vue.use(Antd);
Vue.use(JeecgComponents);
Vue.use(Tooltip)
new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
