import JModal from './JModal'
import JFormContainer from './JFormContainer.vue'
import JUpload from './JUpload.vue'
import HUpload from './HUpload.vue'
import JImageUpload from './JImageUpload.vue'
import JDate from './JDate.vue'
import JSearchSelect from './SearchSelect.vue'
import { Modal, notification,message } from 'ant-design-vue'
import _ from 'lodash'
import {Base64} from 'js-base64'
// import debounce from 'lodash/debounce'
// import pick from 'lodash.pick'
// import data from 'china-area-data'

export default {
  install(Vue) {
    Vue.component('JModal', JModal)
    Vue.component('JFormContainer', JFormContainer)
    Vue.component('JDate', JDate)
    Vue.component('JImageUpload', JImageUpload)
    Vue.component('JUpload', JUpload)
    Vue.component('HUpload', HUpload)
    Vue.component('JSearchSelect', JSearchSelect)


    //提示
    Vue.prototype.$Jnotification = notification
    Vue.prototype.$Jmodal = Modal
    Vue.prototype.$Jmessage = message
    Vue.prototype.$Jlodash = _
    Vue.prototype.$Base64 = Base64
    // Vue.prototype.$Jdebounce= debounce
    // Vue.prototype.$Jpick = pick
    // Vue.prototype.$Jpcaa = data
  }
}