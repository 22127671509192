// 导入封装好的axios实例
import request from './request'
import qs from 'qs'

//deleteAction
export function deleteActionApi(url,parameter) {
    return request({
		url: url,
		method: 'delete',
		params: parameter
    })
}
//post method= {post | put}
export function HttpAction(url,parameter,method) {
	return request({
		url: url,
		method:method ,
		data: parameter
	})
}

export function GetAtion(url,parameter) {
	return request({
		url: url,
		method: 'get',
		params: parameter
	})
}

export function PostAtion(url,parameter) {
	return request({
		url: url,
		method: 'post',
		data: parameter
	})
}
export function PostJson(url,parameter) {
	return request({
		url: url,
		method: 'post',
		data: parameter,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		}
	})
}
/********
 * post-json 方法 不限制超时时间
 */
export function PostJsonOver(url,parameter) {
	return request({
		url: url,
		method: 'post',
		data: parameter,
		headers: {
			'Content-Type': 'application/json;charset=UTF-8'
		},
		timeout: 60 * 60 * 100000
	})
}

export function PostForm(url,parameter) {
	return request({
		url: url,
		method: 'post',
		data: qs.stringify(parameter),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
		}
	})
}

/********
 * get 方法 不限制超时时间
 */
export function newGetAtion(url,parameter) {
	return request({
		url: url,
		method: 'get',
		params: parameter,
		timeout: 60 * 60 * 100000
	})
}

/**
 *
 *身份证验证(调用阿里云的接口)
 * @export
 * @param {*} url
 * @param {*} parameter
 * @return {*} 
 */
export function regIdentApi(url,parameter) {
	return request({
		url: url,
		method: 'get',
		params: parameter,
		headers: {
			'Authorization':'APPCODE 2cb473ecdaa3425ab47b99ea187686b6'
		}
	})
}
