import axios from "axios";
import { Toast } from "vant";
import store from "@/store/index";

// console.log('baseURL',process.env.BASE_API);
// 创建 axios 实例
const service = axios.create({
	// baseURL: process.env.BASE_API,
	timeout: 9000, // 请求超时时间
});

// 2.请求拦截器
service.interceptors.request.use(

	(config) => {
		//发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
		//注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
		const token = store.state.token; //这里取token之前，你肯定需要先拿到token,存一下
		console.log('token====',token);
    let baseURL=config.url.replace(process.env.VUE_APP_API_ACI_URL,""); 
    //增加判断,短信中间跳转页面不需要TOKEN 2022/5/23 
		if (token && 
      baseURL!="/sfsj-server/sys/message/urlSwitch/queryById") {
			//       config.params = { token: token }; //如果要求携带在参数中
			config.headers["X-Access-Token"] = token; //如果要求携带在请求头中
		}
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// 3.响应拦截器
service.interceptors.response.use(
	(response) => {
		//接收到响应数据并成功后的一些共有的处理，关闭loading等
		return response.data;
	},
	(error) => {
		/***** 接收到异常响应的处理开始 *****/
		if (error && error.response) {
		// 1.公共错误处理
		// 2.根据响应码具体处理
		switch (error.response.status) {
			case 400:
			error.message = "错误请求";
			break;
			case 401:
			error.message = "未授权，请重新登录";
			break;
			case 403:
			error.message = "拒绝访问";
			break;
			case 404:
			error.message = "请求错误,未找到该资源";
			//   window.location.href = "/NotFound";
			break;
			case 405:
			error.message = "请求方法未允许";
			break;
			case 408:
			error.message = "请求超时";
			break;
			case 500:
			error.message = "服务器端出错";
			break;
			case 501:
			error.message = "网络未实现";
			break;
			case 502:
			error.message = "网络错误";
			break;
			case 503:
			error.message = "服务不可用";
			break;
			case 504:
			error.message = "网络超时";
			break;
			case 505:
			error.message = "http版本不支持该请求";
			break;
			default:
			error.message = `连接错误${error.response.status}`;
		}
		} else {
			// 超时处理
			if (JSON.stringify(error).includes("timeout")) {
				// Message.error("服务器响应超时，请刷新当前页");
				Toast.fail("服务器响应超时，请刷新当前页");
			}
			error.message = "连接服务器失败";
		}
		Toast.fail(error.message);
		/***** 处理结束 *****/
		//如果不需要错误处理，以上的处理过程都可省略
		return Promise.resolve(error.response?.data);
	}
);
//4.导入文件
export default service;
