import {
	newGetAtion,//超时设置
  GetAtion, //get
  HttpAction, //post/put
  deleteActionApi, //delete
  PostJson, //post-json
  PostJsonOver,//post-json 超时设置
  PostForm, //post-form
  regIdentApi, //阿里云的身份验证
} from "@/utils/http";

const staticDomainURL = `${process.env.VUE_APP_API_BASE_URL}/sys/common/static`
const domianURL =  process.env.VUE_APP_API_BASE_URL
const baseUrl = `${process.env.VUE_APP_API_ACI_URL}/`;



const extra = "h5/filter/";
const getChargeCompanyList = (params)=>GetAtion(baseUrl + "hr-server/tShareholderGd/getChargeCompanyList1", params);
const queryDepartTreeList = (params)=>GetAtion(baseUrl + "sys/sysDepart/queryTreeList",params);

// 请求echs数据
const getechsData=(params)=>PostJson(baseUrl+'/hr-server/tShareholderGd/getChargeCompanyList',params)
// 根据部门主键查询用户信息
const queryUserByDepId = (params)=>GetAtion(baseUrl + "sys/user/queryUserByDepId",params);

const getUserLists = (params)=>GetAtion(baseUrl + "sys/user/list",params);
const queryall = (params)=>GetAtion(baseUrl + "sys/role/queryall",params);

//获取秘钥
const secret = (params) =>
	GetAtion(`${baseUrl}activiti-server/${extra}secret`, params);

//h5的登录校验
const checkLogin = (params) =>
	PostForm(`${baseUrl}loan-server/${extra}check`, params);

//获取借款信息
const getLoanData = (params) =>
	GetAtion(`${baseUrl}loan-server/loan/loanApply/queryById`, params);
const getHrProjectData = (params) =>
	GetAtion(`${baseUrl}hr-server/hrProject/queryById`, params);
//获取对应借款信息的rate
const getRate = (params) =>
	GetAtion(`${baseUrl}loan-server/loan/loanInterestRate/getRate`, params);

//审批历史jilu
const historyList = (params) => PostJson(baseUrl + "activiti-server/activiti/process/listHistory",params);
//根据activiti中间表获取流程数据
const getProcessByActi = (params) => GetAtion(baseUrl + `activiti-server/activiti/definition/find/${params}`);
//根据instanceid 获取taskid
const getTaskId = (params) => GetAtion(baseUrl + `activiti-server/${extra}process/${params}`);

//审核
const completeHandler = (params) => PostJson(baseUrl + "activiti-server/activiti/process/complete",params);

//发文管理
const getOfficialDocuments = (params) => GetAtion(baseUrl + 'operator-server/posting/posting/check',params);
const getOfficialDocumentsPdf = (params) => GetAtion(baseUrl + 'operator-server/posting/posting/pdf',params);
const OfficialDocumentsSeal = (params) => PostJson(baseUrl + "operator-server/operatorRedtitleElectronicSeal/add",params);
//二维码发文详情
const getqrcodedetails = (params) => GetAtion(baseUrl + "operator-server/posting/posting/postingInfo",params);

//二维码经营项目详情
const getJYqrcodedetails = (params) => GetAtion(baseUrl + "testcenter-server/project/jcMarketClue/queryById",params);

//多附件盖章管理
const getFileLists = (params) => GetAtion(baseUrl + "operator-server/sealApply/apply/files",params);
const getfiledetail = (params) => GetAtion(baseUrl + "oa-server/seal/oaSealApply/queryById",params);
// 智勋用印
const getZXFileLists = (params) => GetAtion(baseUrl + "operator-server/sealNew/oaSealApplyNew/apply/files",params); 
const getPngByFileName = (params) => newGetAtion(baseUrl + "operator-server/sealApply/apply/check",params);
const saveSealConfig = (params) => PostJsonOver(baseUrl + "operator-server/operatorApplyElectronicSeal/add",params);
const saveQrCodeConfig =  (params) => PostJson(baseUrl + "operator-server/sealApply/apply/fileQrCode",params);
const getfileQrCodeState = (params) => newGetAtion(baseUrl + "/operator-server/sealApply/fileQrCodeState",params);
/***经营系统**** */
const getbiddingInfo = (params) => GetAtion(baseUrl + "operator-server/tender/operatorTenderDetails/queryById", params)
// 合同盖章
const saveSignature = (params) => PostJson(baseUrl + "operator-server/operatorElectronicSeal/add",params);
const automaticSeal = (params) => PostForm(baseUrl + "operator-server/signature/auto/contract",params);
const getChapter = (params) => GetAtion(baseUrl + "operator-server/operator/seal/list", params)
const getContactTemplates = (params) => GetAtion(baseUrl + "operator-server/operatorContractTemplate/list", params)
const generateContactTemplate = (params) => GetAtion(baseUrl + "operator-server/operatorContractTemplate/contract/generation", params)
const isGenerateContact = (params) => newGetAtion(baseUrl + "operator-server/operatorContractTemplate/contract/check", params)
const transformContactToPdf = (params) => GetAtion(baseUrl + "operator-server/operatorContractTemplate/contract/pdf", params)
const getContractPdf = (params) => newGetAtion(baseUrl + "operator-server/operatorContractTemplate/pdf/preview", params)

//合同附件
const getConSuppleByFileName = (params) => newGetAtion(baseUrl + "operator-server/operatorContractTemplate/contractAttchment/check", params)
const saveContactSealConfig = (params) => PostJson(baseUrl + "operator-server/operatorElectronicSealAttachment/add",params);
const getBlankContactPdf = (params) => newGetAtion(baseUrl + "operator-server/operatorContractTemplate/attachmentPdf/preview", params)
	//合同模板(合约标准模板)
	const getContactTemp = (params) => GetAtion(baseUrl + "operator-server/operator/standardContractTemplate/list", params)
			/*****房屋排查报告二维码**** */
// const pcQrCode = (params) => PostJson(baseUrl + "hr-server/hr/hrRecruitmentInfo/add",params);
const getPcInfo = (params) => GetAtion(baseUrl+"testcenter-server/project/jcMarketClue/queryFromQrcode",params);
 const getProjectInfo = (params) => GetAtion(baseUrl+'operator-server/pdfseal/operatorPdfSealApply/pdfDetH5', params)
//  智勋pdf
const getZXProjectInfo = (params) => GetAtion(baseUrl+'operator-server/sealNew/operatorPdfSealApplyNew/pdfDetH5', params)
//pdf图纸盖章
const getPdfChapter = (params) => newGetAtion(baseUrl + "operator-server/pdfseal/operatorPdfSealApply/pdfSeals", params)
const savePdfSignature = (params) => PostJson(baseUrl + "operator-server/pdfseal/operatorPdfSealApply/savePdfSealMode",params);
const getPdfs = (params) => newGetAtion(baseUrl + "operator-server/pdfseal/operatorPdfSealApply/pdfPreview", params)
const getPdfTotal = (params) => newGetAtion(baseUrl + "operator-server/pdfseal/operatorPdfSealApply/getPdfPageCount", params)

const getContractInfo = (params) => GetAtion(baseUrl + 'operator-server/tender/jhManageContract/queryById', params)
const getCustomerInfo = (params) => GetAtion(baseUrl + 'operator-server/tender/customer/queryById', params)
const getContractSupplement = (params) => GetAtion(baseUrl + 'operator-server/supplementary/queryById', params)
//获取pdf盖章页面所需参数
const getpdfSealsParam = (params) => GetAtion(baseUrl + 'operator-server/pdfseal/operatorPdfSealApply/pdfSealsParam', params)

//签字人申请详情
const getSignedApplication = (params) => GetAtion(baseUrl + 'operator-server/pdfseal/operatorPdfSealApply/getSignApplyById', params)

//签字人申请审批
const ConsentSignedApplication = (params) => PostJson(baseUrl + "/activiti-server/activiti/process/complete",params);
//table数据
const gettabledata = (params) => newGetAtion(baseUrl + "wechat-server/wx/messagew/bidRank",params);



/*****检测合同盖章******* */
const getTestPreview = (params) => newGetAtion(baseUrl + "operator-server/operator/test/check", params)
const getTestSealFileList = (params) => newGetAtion(baseUrl + "operator-server/operator/test/list", params)
const addTestSeal = (params) => PostJson(baseUrl + "operator-server/operator/test/addSeal",params);
const getTestContactInfo = (params) => GetAtion(baseUrl + 'testcenter-server/contract/jcManageContract/queryByIdQrCode', params)
const  getqueryByIdNumber= (params) => GetAtion(baseUrl +'testcenter-server/jc/jcPersonCertificate/queryByIdNumber', params)
const  getTestContactInfo1= (params) => GetAtion(baseUrl +'market-server/contract/marketManageContract/queryByIdQrCode', params)
const getTestContactSup = (params) => GetAtion(baseUrl + 'testcenter-server/contract/supplementary/queryById', params)
const getTestContactSup1 = (params) => GetAtion(baseUrl + 'market-server/contract/marketManageContractSupplementary/queryById', params)
const contactSignPdfList = (params) => GetAtion(`${baseUrl}testcenter-server/contract/jcManageContract/getFiles`,params);
const contactSignPdfList1 = (params) => GetAtion(`${baseUrl}/market-server/contract/marketManageContract/getFiles`,params);

const getPcdetails = (params) => GetAtion(`${baseUrl}testcenter-server/jc/jcCheckListData/queryById`,params);


/*****人力资源平台********* */
const getPromoteChart = (params) => GetAtion(baseUrl+"hr-server/hr/hrPromoteLevel/find/promote/route",params);
const getCurrentUserChart = (params) => PostForm(baseUrl+"hr-server/hr/hrPromote/find/user/promote",params);
const addRecruitPool = (params) => PostJson(baseUrl + "hr-server/hr/hrRecruitmentInfo/add",params);
const addTalentPool = (params) => PostJson(baseUrl + "hr-server/hr/talentPool/add",params);
const getRecruitPool = (params) => GetAtion(baseUrl+"hr-server/hr/hrRecruitmentInfo/get/user",params);
const getUserList = (params) => GetAtion(baseUrl+"hr-server/hr/hrPromote/list",params);

/*******数据字典 */
const getDict = (params) => GetAtion(baseUrl+"sfsj-server/sys/dict/getDictItem",params);
/*****获取附件list***** */
const getFile = (params) => GetAtion(baseUrl+"sfsj-server/sys/oss/api/list",params);

/*******全国地图数据 */
const getmapDataList = (params) => GetAtion(baseUrl+"wechat-server/wechat/jhManageContract/coverageRatio",params);

export {
	getProjectInfo,
  getmapDataList,
  getContactTemp,
  gettabledata,
  getPcInfo,
	baseUrl,
	staticDomainURL,
	domianURL,
	queryall,
	secret,
	checkLogin,
	getLoanData,
	getRate,
	getProcessByActi,
	getTaskId,
	historyList,
	completeHandler,
	getfileQrCodeState,
	getbiddingInfo,

	getContactTemplates,
	generateContactTemplate,
	isGenerateContact,
	transformContactToPdf,
	getContractPdf,
	getContractInfo,
	getCustomerInfo,
	getContractSupplement,
	getConSuppleByFileName,
	saveContactSealConfig,
	getBlankContactPdf,

	getOfficialDocuments,
	getOfficialDocumentsPdf,
	OfficialDocumentsSeal,
	getqrcodedetails,

	getFileLists,
	getfiledetail,
	getPngByFileName,
	saveSealConfig,
	saveQrCodeConfig,


	saveSignature,
	automaticSeal,
	getChapter,
	getUserLists,
	getPdfChapter,
	savePdfSignature,
	getPdfs,
	getPdfTotal,

	getTestPreview,
	getTestSealFileList,
	addTestSeal,
	getTestContactInfo,
	getTestContactInfo1,
	getqueryByIdNumber,
	getTestContactSup,
	getTestContactSup1,
	contactSignPdfList,
	contactSignPdfList1,
	getPcdetails,

	getPromoteChart,
	getCurrentUserChart,
	addRecruitPool,
	addTalentPool,
	getRecruitPool,
	getUserList,

	getDict,
	getFile,

	queryUserByDepId,
	queryDepartTreeList,
	getChargeCompanyList,
	getHrProjectData,
  getechsData,
  getJYqrcodedetails,

  getpdfSealsParam,
  getSignedApplication,
  ConsentSignedApplication,
  getZXProjectInfo,
  getZXFileLists
};
